<template>
	<layout-user :showLogout=true :showBadges=false :showMenu=false>
		<form class="container" :action="action" method="post">
			<div class="col-xs-12">
				<h4 class="text-center">Two-Factor Login</h4>

				<p>Please enter the 6-digit code from your two-factor authentication app.</p>
				<p>If you have lost or replaced your two-factor authentication device, please contact IT at
				   support@westmont.edu or (805) 565-6111 for assistance.</p>

				<input type="hidden" name="StateId" v-model="stateId">
				<fieldset>
					<transition fade>
						<b-alert :show="message!==''" variant="danger">{{ message }}</b-alert>
					</transition>
					<div class="form-group">
						<input id="code" name="code" class="form-control" v-model="code" type="text" min="1"
						       max="999999" maxlength="6" placeholder="6-digit code"
						       inputmode="numeric" pattern="[0-9]*" autocomplete="one-time-code"
						       @keydown="onlyNumber"
						       :readonly="submitted">
					</div>
					<div class="form-group">
						<button class="btn btn-block btn-westmont" type="submit" @onClick="verify"
						        :disabled="(code.length < 1) || submitted">
							<b-spinner small v-show="submitted"></b-spinner>
							Continue
						</button>
					</div>
				</fieldset>
			</div>
		</form>
	</layout-user>
</template>

<script>
import LayoutUser from '@/layouts/user'

const validNonNumericKeys = [
	'Enter',
	'Backspace'
];

// we do not hijack the continue button as we allow simplesaml to do it's job
export default {
	name       : 'MultiFactorVerify',
	components : {
		LayoutUser
	},
	data       : () => {
		return {
			message   : '',
			submitted : false,
			stateId   : '',
			action    : '',
			code      : ''
		}
	},
	created()
	{
		// forcefully hide the menu
		this.$profile.verify = true;
		let fe               = document.getElementById('2fa-form'),
		    si               = document.getElementById('StateId');
		this.action          = fe && fe.getAttribute('action');
		si && (this.stateId = si.value);

		if (window.hasOwnProperty('page_errors') && window.page_errors.length > 0) {
			this.message = window.page_errors[0];
		}
	},
	mounted()
	{
		document.getElementById('code').focus()
	},
	methods : {
		onlyNumber($event)
		{
			if (validNonNumericKeys.indexOf($event.key) === -1 && !($event.metaKey || $event.ctrlKey)) {
				let keyCode = $event.key.charCodeAt(0);
				if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
					$event.preventDefault();
				}
			}
			else if ($event.key === 'Enter') {
				this.verify();
			}
		},
		verify()
		{
			return this.code !== "";
		}
	}
}
</script>
