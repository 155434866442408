<template>
	<layout-user>
	<form @submit.stop.prevent="checkForm">
		<b-tabs content-class="mt-3" align="center" v-show="!this.$profile.isLoggedIn">
			<b-tab title="Create Password" @click="activeType='create'" :active="activeType=='create'"></b-tab>
			<b-tab title="Change Password" @click="activeType='change'" :active="activeType=='change'"></b-tab>
		</b-tabs>
		<div class="container">
			<div class="alert alert-warning" v-if="activeType==='create'">
				If you are a new user, you don't know your password, or your current password is not working. Otherwise please choose Change Password.
			</div>
			<div class="alert alert-warning" v-if="activeType==='change'">
				To CHANGE your password, you'll need your current password.
			</div>
			<transition name="fade">
				<b-alert :show="password_changed === true" variant="success">
					Your password has been updated.
					<router-link to="/" v-show="!this.$profile.isLoggedIn"><a class="action-link">Please login.</a></router-link>
				</b-alert>
			</transition>
			<div class="form-group">
				<label>Username</label>
				<b-form-input v-model="user.username" placeholder="ex: jsmith" required :state="username_valid " :readonly="submitted || profile"></b-form-input>
				<b-form-invalid-feedback :state="username_valid">{{ errors.username }}</b-form-invalid-feedback>
			</div>
			<transition-expand>
				<fieldset v-if="activeType==='create'">
					<div class="form-group">
						<label >Last Name</label>
						<b-form-input v-model="user.lastname" placeholder="Last Name" required :readonly="submitted || profile"></b-form-input>
					</div>
					<div class="form-group">
						<label for="dob">Birthdate</label>
						<b-input-group class="mb-3">
							<b-form-input id="dob" v-model="date_of_birth" @change="updateDatePicker" type="text"  placeholder="Birthdate (mm/dd/yyyy)" autocomplete="off"  :readonly="submitted"></b-form-input>
							<b-input-group-append>
								<b-form-datepicker
									v-model="user.dob"
									:date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
									button-only right
									:readonly="submitted"
									locale="en-US" aria-controls="dob" @context="onDateChange"></b-form-datepicker>
							</b-input-group-append>
						</b-input-group>
						<b-form-invalid-feedback :state="errors.dob ? true : null">{{ errors.dob }}</b-form-invalid-feedback>
					</div>
					<div class="form-group">
						<label >Last 4 digits of your <abbr title="Social Security Number">SSN</abbr> or assigned PIN</label>
						<b-form-input v-model="user.last4ssn" placeholder="Last 4 of SSN or assigned PIN" required :readonly="submitted"></b-form-input>
					</div>
				</fieldset>
			</transition-expand>
			<transition-expand>
			<fieldset v-if="activeType==='change'">
				<div class="form-group">
					<label>Your CURRENT password</label>
					<b-form-input type="password" v-model="user.oldpasswd" required :readonly="submitted"></b-form-input>
				</div>
			</fieldset>
			</transition-expand>
			<div class="form-group">
				<label>Enter a new password</label>
				<b-form-input type="password"v-model="user.newpasswd" required :state="newpasswd_valid" :readonly="submitted"></b-form-input>
				<b-form-invalid-feedback :state="newpasswd_valid">{{ errors.newpasswd }}</b-form-invalid-feedback>
			</div>
			<div class="form-group">
				<label>Retype the new password</label>
				<b-form-input type="password" v-model="user.newpasswd2" required :state="newpasswd2_valid":readonly="submitted"></b-form-input>
				<b-form-invalid-feedback :state="newpasswd2_valid">{{ errors.newpasswd2 }}</b-form-invalid-feedback>
			</div>
			<div class="alert alert-info">
				Password changes affect logins to most Westmont services.
			</div>
			<b-alert :show="form_valid === false" variant="danger">{{ errors.info }}</b-alert>
			<transition name="fade">
				<b-alert :show="password_changed === true" variant="success">
					Your password has been updated.
					<router-link to="/" v-show="!this.$profile.isLoggedIn"><a class="action-link">Please login.</a></router-link>
				</b-alert>
			</transition>
			<button class="btn btn-block btn-westmont":disabled="submitted">
				<b-spinner small v-show="submitted"></b-spinner>
				<span v-if="activeType==='create'">Create Password</span>
				<span v-else>Change Password</span>
			</button>
			<div class="text-center bottom">
				<router-link to="/profile" v-show="this.$profile.isLoggedIn"><a class="action-link">Cancel</a></router-link>
				<router-link to="/" v-show="!this.$profile.isLoggedIn"><a class="action-link">Cancel</a></router-link>
			</div>
		</div>
	</form>
	</layout-user>
</template>
<script>
	import axios from 'axios'
	import TransitionExpand from "@/components/TransitionExpand";
	import LayoutUser from '@/layouts/user'

	// we do not hijack the login button as we allow simplesaml to do it's job
	export default {
		name       : 'PasswordForm',
		props      : ['type'],
		components : {
			TransitionExpand,
			LayoutUser
		},
		created() {
			// wipes out any errors coming from else where
			this.$alerts = [];
			this.activeType = this.type;
			if (this.$profile.isLoggedIn) {
				this.profile       = true;
				this.user.username = this.$profile.username;
				this.user.lastname = this.$profile.lastName;
			}
		},
		data       : () => {
			return {
				errors           : {},
				form_valid       : null,
				username_valid   : null,
				newpasswd_valid  : null,
				newpasswd2_valid : null,
				password_changed : false,
				submitted        : false,
				profile          : false,
				activeType       : 'create',
				date_of_birth    : '',
				user             : {
					username   : '',
					lastname   : '',
					dob        : '',
					oldpasswd  : '',
					newpasswd  : '',
					newpasswd2 : '',
				},
				typeOptions      : [
					{
						text  : 'Change Password',
						value : 'change'
					},
					{
						text  : 'Create Password',
						value : 'create'
					},
				]
			}
		},
		methods : {
			updateDatePicker() {
				try {
					this.user.dob = new Date(this.date_of_birth);
				}
				catch (e) {
					// do nothing
				}
			},
			onDateChange(ctx) {
				if (ctx.selectedFormatted !== 'No date selected') {
					this.date_of_birth = ctx.selectedFormatted
					this.user.dob      = ctx.selectedDate
				}
			},
			/**
			 * Quick client side validations for the most common of issues
			 *
			 * The server is responsible for checking the password is stronger more thoroughly.
			 *
			 * @return {boolean}
			 */
			newpasswdIsOk() {
				let result = true;
				if (this.user.newpasswd.indexOf(this.user.username) >= 0) {
					this.newpasswd_valid = false;
					this.errors['newpasswd'] = "Password may not contain your username";
					result = false;
				}
				else if (this.user.newpasswd.length < 8) {
					this.newpasswd_valid = false;
					this.errors['newpasswd'] = "Password is too short";
					result = false;
				}
				else {
					this.newpasswd_valid = null;
					delete(this.errors['newpasswd']);
				}

				if (this.user.newpasswd !== this.user.newpasswd2) {
					this.newpasswd2_valid = false;
					this.errors['newpasswd2'] = "Passwords do not match";
					result = false;
				}
				else {
					this.newpasswd2_valid = null;
					delete(this.errors['newpasswd2']);
				}

				return result;
			},
			checkForm() {
				if (!this.newpasswdIsOk()) {
					return false;
				}

				this.submitted = true;
				let self = this
				Object.keys(self.errors).forEach(function(key) { delete self.errors[key]; });
				this.form_valid = null;
				this.password_changed = false;
				let data = Object.assign({_METHOD : this.activeType === 'create' ? 'post' : 'patch'}, this.user)
				axios
					.post(`https://${this.$api_url}/my/password`, data)
					.then(response => {
						// reset the form
						this.submitted = false;
						this.password_changed = true;
						for (let key in this.user) {
							if (key === 'username' && this.$profile.isLoggedIn) {
								// do not reset the username field...
								continue;
							}
							this.user.hasOwnProperty(key)  && (this.user[key] = '');
						}
						this.date_of_birth = '';
					})
					.catch(err => {
						this.submitted = false;
						let notify = false;
						if (typeof(err.response) !== 'undefined' && err.response.hasOwnProperty('data')) {
							let response = err.response.data;
							if (response.hasOwnProperty('errors') && Object.keys(response.errors).length > 1) {
								this.errors          = response.errors;
								notify               = true;
								// false = invalid, null = don't display, true = valid
								this.form_valid      = response.errors.hasOwnProperty('info') ? false : null;
								this.username_valid  = response.errors.hasOwnProperty('username') ? false : null;
								this.newpasswd_valid = response.errors.hasOwnProperty('newpasswd') ? false : null;
							}
						}

						if (!notify) {
							this.form_valid = false;
							this.errors['info'] = "An unknown error occurred. If the problem persists please contact Westmont IT";
						}
					})
			}
		}
	}
</script>
<style lang="scss">
.alert a.action-link {
	text-decoration: underline
}
</style>